var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-width-expand"},[(_vm.$can('READ', 'worker-payslip'))?_c('p',{staticClass:"uk-text-left uk-text-bold uk-margin-remove"},[_vm._v(_vm._s(_vm.categoryLabel))]):_vm._e(),(_vm.$can('WRITE', 'worker-payslip') || _vm.$can('READ', 'worker-payslip') || _vm.$can('EDIT', 'worker-payslip') || _vm.$can('DELETE', 'worker-payslip'))?_c('div',{staticClass:"uk-card uk-card-default uk-margin-small-top"},[_c('div',{staticClass:"uk-overflow-auto"},[_c('table',{staticClass:"uk-table uk-table-large uk-table-middle uk-table-striped"},[_c('thead',[_c('tr',[_c('th',{staticClass:"uk-width-1-4 uk-text-left"},[_vm._v("Type")]),_c('th',{staticClass:"uk-width-1-4 uk-text-left"},[_vm._v("Field Name")]),(_vm.$can('EDIT', 'worker-payslip') || _vm.$can('DELETE', 'worker-payslip'))?_c('th',{staticClass:"uk-table-expand uk-text-center"},[_vm._v("Actions")]):_vm._e()])]),(_vm.tableLoading)?_c('Loading',{attrs:{"colspan":3}}):_c('tbody',_vm._l((_vm.fieldList),function(field,index){return _c('tr',{key:index},[(field && field.field_name !== 'highlight')?[_c('td',{staticClass:"uk-width-1-4 uk-text-left"},[_vm._v(_vm._s(field && _vm.preview({ data: field.type_label, limit: 30 }) || '-'))]),_c('td',{staticClass:"uk-width-1-4 uk-text-left"},[_c('div',{staticClass:"uk-flex uk-flex-middle uk-flex-left gap-10px"},[_c('span',{attrs:{"uk-tooltip":field && field.field_name}},[_vm._v(_vm._s(field && _vm.preview({ data: field.field_name, limit: 30 }) || '-'))]),(
                                            (_vm.highlightField && field) &&
                                            (_vm.highlightField.field_value && field.field_name) &&
                                            (_vm.highlightField.field_value === field.field_name)
                                        )?_c('img',{attrs:{"src":(_vm.images + "/star-black.svg"),"alt":"Star icon black"}}):_vm._e()])]),(_vm.$can('EDIT', 'worker-payslip') || _vm.$can('DELETE', 'worker-payslip'))?_c('td',{staticClass:"uk-table-expand uk-text-center"},[_c('div',{staticClass:"uk-flex uk-flex-middle uk-flex-right gap-10px"},[(
                                            (_vm.$can('EDIT', 'worker-payslip')) &&
                                            (field && field.field_name && field.type_label && field.field_name !== ("Notes " + (field.type_label))) &&
                                            (_vm.highlightField && _vm.highlightField.field_value === field.field_name)
                                        )?_c('div',{staticClass:"uk-width-expand"},[_c('button',{staticClass:"uk-text-center uk-button uk-width-expand uk-border-rounded myrobin-border-red myrobin-color-red",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFieldAsHighlight({
                                                action: 'remove-field-as-highlight',
                                                typeLabel: field && field.type_label,
                                                typeValue: field && field.type_value,
                                                fieldName: field && field.field_name
                                            })}}},[_vm._v("Remove Highlight")])]):_vm._e(),(
                                            (_vm.$can('EDIT', 'worker-payslip')) &&
                                            (field && field.field_name && field.type_label && field.field_name !== ("Notes " + (field.type_label))) &&
                                            (_vm.highlightField && _vm.highlightField.field_value !== field.field_name)
                                        )?_c('div',{staticClass:"uk-width-expand",on:{"click":function($event){return _vm.setAsHighlight({
                                            action: 'set-as-highlight',
                                            typeLabel: field && field.type_label,
                                            typeValue: field && field.type_value,
                                            fieldName: field && field.field_name
                                        })}}},[_c('button',{staticClass:"uk-text-center uk-button uk-width-expand uk-border-rounded myrobin-border-green myrobin-color-green",attrs:{"type":"button"}},[_vm._v("Set as Highlight")])]):_vm._e(),(
                                            (_vm.$can('EDIT', 'worker-payslip')) &&
                                            (field && field.field_name && field.type_label && field.field_name !== ("Notes " + (field.type_label)))
                                        )?_c('div',{staticClass:"uk-width-expand",on:{"click":function($event){return _vm.editField({
                                            action: 'edit-field',
                                            typeLabel: field && field.type_label,
                                            typeValue: field && field.type_value,
                                            fieldName: field && field.field_name
                                        })}}},[_c('button',{staticClass:"uk-text-center uk-button uk-button-primary uk-width-expand uk-border-rounded",attrs:{"type":"button"}},[_vm._v("Edit")])]):_vm._e(),(
                                            (_vm.$can('EDIT', 'worker-payslip')) &&
                                            (field && field.field_name && field.type_label && field.field_name !== ("Notes " + (field.type_label)))
                                        )?_c('div',{staticClass:"uk-width-expand",on:{"click":function($event){return _vm.deleteField({
                                            action: 'delete-field',
                                            typeLabel: field && field.type_label,
                                            typeValue: field && field.type_value,
                                            fieldName: field && field.field_name
                                        })}}},[_c('button',{staticClass:"uk-text-center uk-button uk-button-danger uk-width-expand uk-border-rounded",attrs:{"type":"button"}},[_vm._v("Delete")])]):_vm._e()])]):_vm._e()]:_vm._e()],2)}),0)],1),(_vm.$can('WRITE', 'worker-payslip'))?_c('div',{staticClass:"uk-padding-small"},[_c('div',{staticClass:"uk-flex uk-flex-middle uk-flex-center"},[_c('button',{staticClass:"uk-button myrobin-color-blue myrobin-border-none",attrs:{"type":"button"},on:{"click":function($event){return _vm.addField({
                            action: 'add-field',
                            typeLabel: _vm.categoryLabel,
                            typeValue: _vm.categoryValue,
                            fieldName: ''
                        })}}},[_c('div',{staticClass:"uk-flex uk-flex-middle uk-flex-center gap-10px"},[_c('img',{attrs:{"src":(_vm.images + "/circle-add-blue.svg"),"alt":"Circle add blue icon"}}),_c('p',{staticClass:"uk-margin-remove"},[_vm._v("Add Field")])])])])]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }