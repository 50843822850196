<template>
    <div class="uk-width-expand">
        <p v-if="$can('READ', 'worker-payslip')" class="uk-text-left uk-text-bold uk-margin-remove">{{ categoryLabel }}</p>
        <div
            v-if="$can('WRITE', 'worker-payslip') || $can('READ', 'worker-payslip') || $can('EDIT', 'worker-payslip') || $can('DELETE', 'worker-payslip')"
            class="uk-card uk-card-default uk-margin-small-top"
        >
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-large uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-width-1-4 uk-text-left">Type</th>
                            <th class="uk-width-1-4 uk-text-left">Field Name</th>
                            <th
                                v-if="$can('EDIT', 'worker-payslip') || $can('DELETE', 'worker-payslip')"
                                class="uk-table-expand uk-text-center"
                            >Actions</th>
                        </tr>
                    </thead>
                    <Loading v-if="tableLoading" :colspan="3" />
                    <tbody v-else>
                        <tr v-for="(field, index) in fieldList" :key="index">
                            <template v-if="field && field.field_name !== 'highlight'">
                                <td class="uk-width-1-4 uk-text-left">{{ field && preview({ data: field.type_label, limit: 30 }) || '-' }}</td>
                                <td class="uk-width-1-4 uk-text-left">
                                    <div class="uk-flex uk-flex-middle uk-flex-left gap-10px">
                                        <span :uk-tooltip="field && field.field_name">{{ field && preview({ data: field.field_name, limit: 30 }) || '-' }}</span>
                                        <img
                                            v-if="
                                                (highlightField && field) &&
                                                (highlightField.field_value && field.field_name) &&
                                                (highlightField.field_value === field.field_name)
                                            "
                                            :src="`${images}/star-black.svg`"
                                            alt="Star icon black" 
                                        />
                                    </div>
                                </td>
                                <td v-if="$can('EDIT', 'worker-payslip') || $can('DELETE', 'worker-payslip')" class="uk-table-expand uk-text-center">
                                    <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                                        <div
                                            v-if="
                                                ($can('EDIT', 'worker-payslip')) &&
                                                (field && field.field_name && field.type_label && field.field_name !== `Notes ${field.type_label}`) &&
                                                (highlightField && highlightField.field_value === field.field_name)
                                            "
                                            class="uk-width-expand"
                                        >
                                            <button
                                                type="button"
                                                class="uk-text-center uk-button uk-width-expand uk-border-rounded myrobin-border-red myrobin-color-red"
                                                @click="removeFieldAsHighlight({
                                                    action: 'remove-field-as-highlight',
                                                    typeLabel: field && field.type_label,
                                                    typeValue: field && field.type_value,
                                                    fieldName: field && field.field_name
                                                })"
                                            >Remove Highlight</button>
                                        </div>
                                        <div
                                            v-if="
                                                ($can('EDIT', 'worker-payslip')) &&
                                                (field && field.field_name && field.type_label && field.field_name !== `Notes ${field.type_label}`) &&
                                                (highlightField && highlightField.field_value !== field.field_name)
                                            "
                                            class="uk-width-expand"
                                            @click="setAsHighlight({
                                                action: 'set-as-highlight',
                                                typeLabel: field && field.type_label,
                                                typeValue: field && field.type_value,
                                                fieldName: field && field.field_name
                                            })"
                                        >
                                            <button
                                                type="button"
                                                class="uk-text-center uk-button uk-width-expand uk-border-rounded myrobin-border-green myrobin-color-green"
                                            >Set as Highlight</button>
                                        </div>
                                        <div
                                            v-if="
                                                ($can('EDIT', 'worker-payslip')) &&
                                                (field && field.field_name && field.type_label && field.field_name !== `Notes ${field.type_label}`)
                                            "
                                            class="uk-width-expand"
                                            @click="editField({
                                                action: 'edit-field',
                                                typeLabel: field && field.type_label,
                                                typeValue: field && field.type_value,
                                                fieldName: field && field.field_name
                                            })"
                                        >
                                            <button
                                                type="button"
                                                class="uk-text-center uk-button uk-button-primary uk-width-expand uk-border-rounded"
                                            >Edit</button>
                                        </div>
                                        <div
                                            v-if="
                                                ($can('EDIT', 'worker-payslip')) &&
                                                (field && field.field_name && field.type_label && field.field_name !== `Notes ${field.type_label}`)
                                            "
                                            class="uk-width-expand"
                                            @click="deleteField({
                                                action: 'delete-field',
                                                typeLabel: field && field.type_label,
                                                typeValue: field && field.type_value,
                                                fieldName: field && field.field_name
                                            })"
                                        >
                                            <button
                                                type="button"
                                                class="uk-text-center uk-button uk-button-danger uk-width-expand uk-border-rounded"
                                            >Delete</button>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
                <div v-if="$can('WRITE', 'worker-payslip')" class="uk-padding-small">
                    <div class="uk-flex uk-flex-middle uk-flex-center">
                        <button
                            type="button"
                            class="uk-button myrobin-color-blue myrobin-border-none"
                            @click="addField({
                                action: 'add-field',
                                typeLabel: categoryLabel,
                                typeValue: categoryValue,
                                fieldName: ''
                            })"
                        >
                            <div class="uk-flex uk-flex-middle uk-flex-center gap-10px">
                                <img :src="`${images}/circle-add-blue.svg`" alt="Circle add blue icon" />
                                <p class="uk-margin-remove">Add Field</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    name: 'FieldCategoryCardItem',
    components: {
        Loading: () => import('@/components/globals/tables/Loading')
    },
    props: {
        tableLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        categoryLabel: {
            type: String,
            required: true,
            default: 'Tidak Diketahui'
        },
        categoryValue: {
            type: String,
            required: true,
            default: 'Tidak Diketahui'
        },
        fieldList: {
            type: Array,
            required: true,
            default: () => []
        },
        highlightField: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    },
    methods: {
        addField({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('add-field', { action, typeLabel, typeValue, fieldName });
        },
        editField({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('edit-field', { action, typeLabel, typeValue, fieldName });
        },
        deleteField({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('delete-field', { action, typeLabel, typeValue, fieldName });
        },
        setAsHighlight({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('set-as-highlight', { action, typeLabel, typeValue, fieldName });
        },
        removeFieldAsHighlight({ action, typeLabel, typeValue, fieldName }) {
            this.$emit('remove-field-as-highlight', { action, typeLabel, typeValue, fieldName });
        },
        preview({ data, limit }) {
            if (typeof data !== 'string') throw new Error('Data is not string');
            
            if (data.length < limit) {
                return data;
            } else {
                return data.substring(0, limit)+'...';
            }
        }
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}

.myrobin-border-green {
    border: 1px solid #0ABAB5;
}
.myrobin-color-green {
    color: #0ABAB5;
}
.myrobin-border-red {
    border: 1px solid #EF5350;
}
.myrobin-color-red {
    color: #EF5350;
}

.gap-10px {
    gap: 10px;
}
</style>
